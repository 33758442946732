import React from "react";
import AboutBrief from "../Components/About/AboutBrief";
import Beliefs from "../Components/About/Beliefs";
import Team from "../Components/About/Team";
import Schedule from "../Components/About/Schedule";

const About = () => {
  return (
    <div className="large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
      <AboutBrief />
      <Beliefs />
      <Team />
      <Schedule />
    </div>
  );
};

export default About;
