import React from "react";
import tab from "../../Assets/mothers.jpeg";
import { Link } from "react-router-dom";

const Schedule = () => {
  return (
    <div className="flex p-[100px] flex-col mobile:py-10 mobile:px-[18px] smtab:p-10 Tablet:p-14 ">
      <div className="flex justify-between gap-5 flex-wrap w-full">
        <div className="w-[45%] mobile:w-full smtab:w-full">
          <p className="text-medium-3xl font-Cormorant font-bold text-footer tracking-tight leading-10 mobile:text-medium-xl">
            Our Worship Services
          </p>
          <div className="my-5 mobile:my-5">
            <p className="text-2xl leading-relaxed">
              Our church is blessed with a diverse range of ministries dedicated to equip and
              empower members to fulfill their God-given purpose, serve the community, and support
              the church's mission.
            </p>

            <ul className="my-10 text-2xl flex flex-col gap-5 mobile:px-1 mobile:w-full">
              <p className="text-brown text-small-xl font-Cormorant font-semibold">
                Times & Location
              </p>
              <li className="flex flex-col">
                <span className="font-semibold">Sundays</span>
                <span>Morning Service at 10:30am </span>
                <span>Afternoon Service at 3:00pm </span>
              </li>
              <li className=" flex flex-col">
                <span className="font-semibold">Fridays</span>
                <span>6:00pm prompt</span>
              </li>

              <li className="flex flex-col">
                <span className="font-semibold">Vigils</span>
                <span>Every last Friday of the month: 10:00pm Prompt</span>
              </li>
              <li>
                <span className=" ">
                  RCCG Our Saviour’s Parish, 112A Norton Road, Stockton-on-Tees, TS20 2AQ
                </span>
              </li>
            </ul>
          </div>
          <div className="flex gap-5 items-center mobile:gap-3">
            <Link
              className="border-2 border-buttons text-buttons rounded-full px-5 py-2  mobile:px-3"
              to="https://maps.app.goo.gl/LKWB719BxLtN1X1u5"
            >
              Get Driving Directions
            </Link>
            <div>
              <Link
                to="https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE"
                className="px-5 py-3 rounded-full bg-buttons  text-white  mobile:px-3"
              >
                Watch Online
              </Link>
            </div>{" "}
          </div>
        </div>

        <div className="w-[50%] smtab:w-full mobile:w-full rounded-lg overflow-hidden">
          <img src={tab} className="object-cover h-full w-full" alt="display" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
