import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Baselayout from "./Components/Baselayout";
import Homepage from "./Pages/Homepage";
import Giving from "./Pages/Giving";
import About from "./Pages/About";
import Services from "./Pages/Services";
import ContactUs from "./Pages/Contact";
import Serve from "./Pages/Serve";
import Events from "./Pages/Events";
import Magazine from "./Pages/Magazine";
import SingleEvent from "./Pages/SingleEvent";
import Gallery from "./Pages/Gallery";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Baselayout />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/give" element={<Giving />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/serve" element={<Serve />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:eventId" element={<SingleEvent />} />
          <Route path="/magazine" element={<Magazine />} />
          <Route path="/gallery" element={<Gallery />} />
        </Route>
      </>
    )
  );
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
