import React, { useRef, useState } from "react";
import { FaPlay } from "react-icons/fa6";
import { IconContext } from "react-icons";

const Video = ({ vidStyle, video }) => {
  const videoRef = useRef();
  const [showPlay, setShowplay] = useState(true);
  const handlePlay = (e) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowplay(false);
    } else {
      videoRef.current.pause();
      setShowplay(true);
    }
  };

  return (
    <div className={`${vidStyle}`}>
      <div className="  h-auto w-auto rounded-lg overflow-hidden ">
        <video className="object-fit h-full w-full" ref={videoRef} onClick={handlePlay} muted>
          <source src={video} />
        </video>
      </div>
      {showPlay && (
        <div
          className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] p-5  rounded-full bg-[#FCF2E780] z-[100000] inline"
          onClick={handlePlay}
        >
          <IconContext.Provider value={{ size: "40px", color: "#00000073" }}>
            <FaPlay />
          </IconContext.Provider>
        </div>
      )}
    </div>
  );
};

export default Video;
