import React from "react";
import Header from "../Components/Homepage/Header";
import Brief from "../Components/Homepage/Brief";
import NewChurch from "../Components/Homepage/NewChurch";
import OurMinistries from "../Components/Homepage/OurMinistries";
import Live from "../Components/Homepage/Live";
import Testimonials from "../Components/Homepage/Testimonial";

const Homepage = () => {
  return (
    <div className="">
      <Header />
      <div className="large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
        <Brief />
      </div>
      <NewChurch />
      <div className="large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
        <OurMinistries />
        <Live />
        <Testimonials />
      </div>
    </div>
  );
};

export default Homepage;
