import React, { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useNavigate, Link, useLocation } from "react-router-dom";
import "../App.css";
import rccg from "../Assets/image 2.svg";
import Button from "../Resources/Button";
import { RiInstagramFill } from "react-icons/ri";
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { IoIosArrowDown, IoMdShare } from "react-icons/io";
import Footer from "./Footer";
import { AnimatePresence, motion } from "framer-motion";

const Baselayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navref = useRef();

  const navdata = [
    {
      title: "Home",
      path: "/",
    },

    {
      title: "About Us",
      path: "/about",
    },
    {
      title: "Our Services",
      path: "/services",
    },
    {
      title: "Contact",
      path: "/contact-us",
    },
  ];

  const subNav = [
    {
      title: "Serve",
      path: "/serve",
    },
    {
      title: "Events",
      path: "/events",
    },
    {
      title: "OSP Magazine",
      path: "/magazine",
    },

    {
      title: "Gallery",
      path: "/gallery",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [activeNav, setActiveNav] = useState(false);
  const [subNavigate, setSubNavigate] = useState(false);

  // function to set state of the search to inactive
  // after the user clicks outside the box
  const handleClickOutside = (event) => {
    if (navref.current && !navref.current.contains(event.target)) {
      setActiveNav(false);
      setSubNavigate(false);
    }
  };
  //useEffect to respond to any click outside the box
  //revert the search box to inactive
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    // large:w-[1440px]
    <div className="bg-white ">
      <nav className="p-2 flex justify-between items-center w-[85%] rounded-full  fixed top-5 left-[50%] translate-x-[-50%] bg-white z-[10000000] mobile:w-[90%] smtab:w-[90%] Tablet:w-[95%] Tablet:justify-evenly Tablet:gap-5 border border-gray-100">
        <Link to="/" className="flex items-center gap-2">
          <div>
            <img src={rccg} alt="logo" className="h-10" loading="lazy" />
          </div>
          <div className="text-1xl ">
            <p className="font-bold leading-tight">Our Saviour’s Parish</p>
            <p className="leading-tight text-[12px] Tablet:text-[9px] mobile:text-[11px]">
              The Redeemed Christian Church of God
            </p>
          </div>
        </Link>
        {/* the navigation Links */}

        <div
          className={`flex w-[65%] justify-between  mobile:absolute mobile:top-16 mobile:flex mobile:items-end bg-white mobile:w-[95%] rounded-3xl mobile:p-5 smtab:absolute smtab:flex  smtab:top-16 smtab:w-[100%] smtab:left-0 smtab:p-8  smtab:items-end smtab:flex-col smtab:gap-5 smtab:justify-between


            ${activeNav ? "mobile:block smtab:block" : "mobile:hidden smtab:hidden"}`}
          ref={navref}
        >
          <ul className="flex gap-8 item-center h-auto mobile:flex-col smtab:w-full smtab:justify-between">
            {navdata?.map((navItem, index) => (
              <li
                key={index}
                className="group relative h-full  flex items-center text-center"
                onClick={() => setActiveNav(false)}
              >
                <NavLink
                  to={navItem.path}
                  className={({ isActive }) => (isActive ? "font-bold transition-colors flex" : "")}
                >
                  {navItem.title}
                </NavLink>
              </li>
            ))}
            <li className="cursor-pointer relative">
              <div
                className="flex items-center h-full gap-2"
                onClick={() => setSubNavigate(!subNavigate)}
              >
                <p>Explore </p>
                <div className="">
                  <IoIosArrowDown />
                </div>
              </div>

              {subNavigate && (
                <div className=" absolute top-[100%] pt-2 smtab:left-[-50px] mobile:relative mobile:my-3">
                  <ul className=" bg-white py-2 px-5 my-1 rounded-lg text-start  flex flex-col gap-4  mobile:p-0 border border-gray-100 mobile:border-0">
                    {subNav.map((sub, index) => (
                      <li onClick={() => setActiveNav(false)} key={index}>
                        <NavLink
                          key={index}
                          className="text-nowrap p-2 mobile:p-0 mobile:text-"
                          to={sub.path}
                        >
                          {sub.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          </ul>
          {/* //social */}
          <div
            className={`flex gap-2 items-center m-0 p-0  ${
              activeNav
                ? "Tablet:absolute  right-0 top-[110%] bg-white py-1 px-2 mobile:p-0 mobile:m-0 rounded-xl"
                : "Tablet:hidden"
            }`}
          >
            <IconContext.Provider value={{ color: "grey", size: "30px", transition: "5s" }}>
              <div>
                <Link to="https://www.facebook.com/oursavioursparishUK">
                  <RiFacebookBoxFill
                    onMouseOver={({ target }) => (target.style.color = "#007AFF")}
                    onMouseOut={({ target }) => (target.style.color = "#717171")}
                  />
                </Link>
              </div>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "grey", size: "29px", transition: "5s" }}>
              <div>
                <Link to="https://www.instagram.com/rccgoursavioursparish/">
                  <RiInstagramFill
                    onMouseOver={({ target }) => (target.style.color = "#EC615B")}
                    onMouseOut={({ target }) => (target.style.color = "#717171")}
                  />
                </Link>
              </div>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "grey", size: "25px", transition: "5s" }}>
              <div>
                <Link to="https://x.com/rccgoursaviours">
                  <RiTwitterXLine
                    onMouseOver={({ target }) => (target.style.color = "#EC615B")}
                    onMouseOut={({ target }) => (target.style.color = "#717171")}
                  />
                </Link>
              </div>
            </IconContext.Provider>
            {/* <img src={facebook} alt="face" className="hover:outline-[#007AFF]" /> */}
          </div>

          <div className="flex items-center">
            <Button
              title="Give"
              btnStyle="px-5 py-1 bg-buttons font-semibold text-white rounded-full mobile:text-[12px] text-white"
              handleClick={() => {
                navigate("/give");
                setActiveNav(false);
              }}
            />
          </div>
        </div>

        <div
          className={`mobile:block smtab:block hidden px-1 transition-all rotate-[180deg] ${
            !activeNav && "rotate-[360deg]"
          }`}
          onClick={() => setActiveNav(!activeNav)}
        >
          <IconContext.Provider value={{ size: "25px" }}>
            <IoIosArrowDown />
          </IconContext.Provider>
        </div>

        <div
          className={` hidden px-1 Tablet:block transition-all ${!activeNav && "rotate-[-180deg]"}`}
          onClick={() => setActiveNav(!activeNav)}
        >
          <IoMdShare />
        </div>
      </nav>

      <div>
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Outlet />
          </motion.div>
        </AnimatePresence>
      </div>

      <Footer />
    </div>
  );
};

export default Baselayout;
