import React, { useEffect, useState } from "react";
import "../../App.css";
import dancing from "../../Assets/banner1.png";
import Children from "../../Assets/youths.jpg";
import church from "../../Assets/banner2.png";
import seated from "../../Assets/seated.jpg";
import Button from "../../Resources/Button";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [image1, setImage1] = useState(true);
  const [image2, setImage2] = useState(false);
  const [image3, setImage3] = useState(false);
  const [image4, setImage4] = useState(false);

  const [indicator, setIndicator] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (image1) {
        setImage1(false);
        setImage2(true);
        setIndicator(!indicator);
      } else if (image2) {
        setImage3(true);
        setImage1(false);
        setImage2(false);
        setIndicator(!indicator);
      } else if (image3) {
        setImage1(false);
        setImage2(false);
        setImage3(false);
        setImage4(true);
        setIndicator(!indicator);
      } else {
        setImage1(true);
        setImage2(false);
        setImage3(false);
        setIndicator(!indicator);
      }
    }, [4000]);
  }, [image1, image2, image3, indicator]);
  return (
    <div className="relative h-[95vh] overflow-hidden">
      <div className="h-[100%] border-red-500 border-3 absolute top-0 bottom-0 left-0 right-0 overflow-hidden bg-black">
        {image1 ? (
          <img
            src={dancing}
            alt="..."
            className="animate-changeImage object-cover h-full w-full"
            loading="lazy"
          />
        ) : image2 ? (
          <img
            src={Children}
            alt="..."
            className="animate-changeImage2 object-cover h-full w-full"
            loading="lazy"
          />
        ) : image3 ? (
          <img
            src={church}
            alt="..."
            className="animate-changeImage3 object-cover h-full w-full"
            loading="lazy"
          />
        ) : image4 ? (
          <img
            src={seated}
            alt="seated"
            className="animate-changeImage2 object-cover h-full w-full "
            loading="lazy"
          />
        ) : null}
      </div>
      <div className="absolute bottom-[15%] text-center left-[50%] translate-x-[-50%] mobile:w-[80%]">
        <p className="font-Cormorant text-white text-medium-3xl font-semibold mobile:text-small-2xl mobile:leading-10">
          Welcome to a place you can call home
        </p>
        <p className="text-white text-3xl mobile:text-1xl">
          112A Norton Road, Stockton-on-Tees, TS20 2AQ
        </p>
        <div className=" flex items-center justify-center">
          <Button
            title="Our Services"
            btnStyle="bg-buttons text=white font-semibold my-5 text-white"
            handleClick={() => navigate("services")}
          />
        </div>
      </div>

      <div className="rounded-full bg-[#FFFFFF33] p-1 absolute bottom-10 left-[50%] translate-x-[-50%] flex items-center gap-2">
        <div className={`h-3 w-3 rounded-full ${indicator ? "bg-white" : "bg-[#FCF2E780]"}`}></div>
        <div className={`h-3 w-3 rounded-full ${indicator ? "bg-[#FCF2E780]" : "bg-white"}`}></div>
      </div>
    </div>
  );
};

export default Header;
