import React, { useEffect, useState } from "react";
import ellipse from "../../Assets/icons/blank.svg";

export const Testimonials = () => {
  const testimonies = [
    {
      id: 1,
      member: ellipse,
      testimony:
        "RCCG Our Saviour's Parish has truly been a sanctuary for me and my family since we moved abroad. The warmth and love we've received here have made us feel right at home, despite being far from our country. The teachings are deeply rooted in the Word, and the church's commitment to holiness and community outreach has inspired us to grow in our faith.",
      names: "A.A",
    },
    {
      id: 2,
      member: ellipse,
      testimony:
        "Joining RCCG Our Saviour's Parish in the diaspora has been a life-changing experience. The church is not just a place of worship but a family that supports one another through life's challenges. The emphasis on holiness and living a Christ-centered life has transformed my walk with God, and I am grateful for the strong spiritual foundation this church has provided.",
      names: "A.A",
    },
    {
      id: 3,
      member: ellipse,
      testimony:
        "At RCCG Our Saviour's Parish, I found a community that genuinely cares about spreading the gospel and making a difference in people's lives. The commitment to planting churches and reaching every corner of the world is inspiring, and being part of this mission has given me a renewed purpose. This church has empowered me to grow in my faith and actively contribute to God's work.",
      names: "S.S",
    },
    {
      id: 4,
      member: ellipse,
      testimony:
        "RCCG Our Saviour's Parish has been a beacon of light for my family in the diaspora. The teachings are powerful, and the emphasis on holiness as a lifestyle has challenged us to live out our faith in practical ways. The church's dedication to reaching the nations and planting churches worldwide makes us proud to be part of such a global mission. We have truly found a home here.",
      names: "B.A",
    },
  ];
  const [storyIndex, setStoryIndex] = useState(1);
  const [story, setStory] = useState();

  useEffect(() => {
    const sorted = testimonies.filter((index) => storyIndex === index.id);
    setStory(sorted);
    // eslint-disable-next-line
  }, [storyIndex]);

  return (
    <div className="bg-transparent p-[100px] flex justify-between mobile:flex-col mobile:px-[18px] mobile:py-10 gap-10 smtab:p-10">
      <div className="w-[50%] mobile:w-full smtab:w-full">
        <p className="text-brown font-bold text-medium-xl uppercase mb-3">Testimonials</p>
        {/* <h1 className="text-big-2xl font-Cormorant font-bold text-footer leading-tight tracking-tighter mobile:text-small-xl mb-1">
          Growing Together <br /> in God’s Word
        </h1> */}

        <p className="w-[90%] mobile:w-full Tablet:w-full smtab:w-full text-footer ">
          At Our Saviour’s Parish, we are united by the power of God’s Word, growing together in
          faith, hope, and love. Through shared experiences and testimonies, we celebrate the ways
          God is working in our lives, strengthening our community and deepening our trust in Him.
        </p>
      </div>

      {/**testimonial Slider */}
      <div className="flex flex-col gap-5 w-[50%] mobile:w-full smtab:full ">
        <div className="flex gap-5 justify-start items-centerw-full h-full ">
          {testimonies?.map((info) => (
            <div
              className={`relative flex rounded-full overflow-hidden h-14 w-14  ${
                storyIndex === info.id ? `border-[4px] border-footer greyscale-0` : `greyscale`
              }`}
              key={info.id}
              id={info.id}
              onClick={() => setStoryIndex(info.id)}
            >
              {/* <div className="absolute top-0 bottom-0 left-0 right-0 bg-black"></div> */}
              <img
                src={info.member}
                className={`object-fit h-full w-full greyscale transition-all ${
                  storyIndex === info.id ? `greyscale-0 transition-all` : "grayscale	"
                }`}
                alt="memberimage"
                loading="lazy"
              />
            </div>
          ))}
        </div>
        <p>{story?.testimony}</p>
        <div className="w-[100%] mobile:w-full smtab:w-full Tablet:w-full">
          {story?.map((item) => (
            <div key={item.id} className="transition-all ">
              <p className={`${storyIndex === item.id && `animate-fade`}`}>"{item.testimony}"</p>
              <p className="font-semibold mt-5">{item.names}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
