import React, { useEffect, useState } from "react";
import Tab from "../Components/serviceComponents/Tab";
import { fetchMagazines } from "../Resources/request";
import { HashLoader } from "react-spinners";

const Magazine = () => {
  const [loading, setIsLoading] = useState(false);
  const [magList, setMagList] = useState([]);

  const fetchMags = async () => {
    setIsLoading(true);
    let getData = await fetchMagazines();

    if (getData) {
      setIsLoading(false);
      setMagList(getData?.data?.data);
    }
  };

  useEffect(() => {
    fetchMags();
  }, []);

  const magData = magList.map((datas) => ({
    Title: datas?.Title,
    Description: datas?.Description,
    schedule: new Date(datas.schedule).toDateString(),
    images: datas?.images,
    link: datas?.link,
  }));
  return (
    <div className="p-[100px] mobile:px-8 smtab:px-10 Tablet:px-14 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
      <div className="text-center my-5">
        <p className="text-medium-2xl font-Cormorant font-semibold text-footer leading-tight">
          OSP Magazine
        </p>
        <p>Check out what’s happening at OSP!</p>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-[700px]">
          <HashLoader color="#07225C" />
        </div>
      ) : (
        <div className="w-full">
          <Tab dataSet={magData} />
        </div>
      )}
    </div>
  );
};

export default Magazine;
