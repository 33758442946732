import React, { useEffect, useState } from "react";
import Button from "../../Resources/Button";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Form1 = ({
  formState,
  setFormState,
  handleChange,
  submit,
  type,
  isLoading,
  isValid,
  confirmed,
}) => {
  //formstate={firstName, lastName, EmailAddress, PhoneNumber information}
  const [value, setValue] = useState("");
  console.log(isValid);
  useEffect(() => {
    setFormState({
      ...formState,
      Phone: value || "",
    });
    // eslint-disable-next-line
  }, [value]);
  return (
    <div>
      <form onSubmit={submit}>
        <div className="flex flex-col gap-5 mobile:gap-1">
          <div className="flex  items-end justify-between w-full gap-2 mobile:gap-2 mobile:flex-col">
            <div className="flex flex-col w-full">
              <label htmlFor={formState.firstname} className="leading-10">
                Your Name
              </label>
              <input
                type="text"
                placeholder="First name"
                name="Firstname"
                value={formState["Firstname"]}
                onChange={handleChange}
                className="border w-full p-2  rounded-md"
              />
            </div>

            <div className="flex flex-col w-full">
              <label htmlFor={formState.firstname} className="leading-10"></label>
              <input
                type="text"
                placeholder="Last name"
                name="Lastname"
                value={formState["Lastname"]}
                onChange={handleChange}
                className=" w-full border p-2 rounded-md"
              />
            </div>
          </div>

          {/* email and phone number  */}
          <div className="flex items-end justify-between w-full gap-2 mobile:gap-2 mobile:flex-col">
            <div className="flex flex-col w-full">
              <label htmlFor={formState.firstname} className="leading-10">
                Email Address
              </label>
              <input
                type="text"
                placeholder="Email Address"
                name="Email"
                value={formState["Email"]}
                onChange={handleChange}
                className="border w-full  p-2  rounded-md"
              />
            </div>

            <div className="flex flex-col w-full ">
              <label htmlFor={formState.firstname} className="leading-10">
                Phone Number
              </label>
              <div className="border  w-auto p-2 rounded-md">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={value}
                  onChange={setValue}
                  className="w-full"
                />
              </div>
              {/* <input
                type="text"
                placeholder=""
                name="Phone"
                value={formState["Phone"]}
                onChange={handleChange}
                className="border w-full p-2 rounded-md"
              /> */}
            </div>
          </div>

          <div>
            <label htmlFor={formState.info} className="leading-10">
              {type === "questions" ? "Questions" : "Prayer"}
            </label>
            <textarea
              name={type === "questions" ? "Question" : "Prayer"}
              placeholder=""
              value={type === "questions" ? formState["Question"] : formState["Prayer"]}
              onChange={handleChange}
              className="border w-full p-2 rounded-md h-[70px]"
            />
          </div>
        </div>
        <div className="rounded-xl my-5">
          <Button
            btnLoad={isLoading}
            title={
              confirmed === true && type === "questions"
                ? "Your question's have been submitted"
                : confirmed === true && type === "prayer"
                ? "Your prayers have been recieved"
                : "Submit"
            }
            btnStyle={`text-white font-semibold w-full  ${
              isValid ? "bg-buttons " : "bg-gray-400 cursor-not-allowed"
            }`}
            disable={!isValid}
          />
        </div>
      </form>
    </div>
  );
};

export default Form1;
