import img1 from "../Assets/images/Goodwomen.jpeg";
import img2 from "../Assets/images/biblestudies.jpeg";
import img3 from "../Assets/images/vigilss.jpeg";

export const serviceList = [
  {
    images: img1,
    title: "Sunday Worship Service",
    bodyText:
      "Experience a welcoming community every Sunday! Our service includes inspiring worship, an uplifting message, and a friendly atmosphere where every one feels at home. Kids will love our engaging children’s program, and after service an opportunity to connect with other people. Dress comfortably come as you are, and find a place to grow and belong. We can’t wait to see you. ",
    timeFrame: "Meets Sunday morning service 10:30am and Afternoon Service 3pm",
  },
  {
    images: img2,
    title: "Combined Bible Study & Prayer Services",
    bodyText:
      "Join us each week for Bible Study and Prayer Service a welcoming time to grow in faith and connect with others. We start with a relaxed Bible Study, exploring scripture’s meaning for our lives, followed by group prayer, lifting up our needs and hopes. Whether you’re new to Bible Study or seeking to deepen your faith, all are welcome to this evening of learning, prayer, and fellowship.",
    timeFrame: "Meets Fridays at 6pm.",
  },
  {
    images: img3,
    title: "Monthly Vigil",
    bodyText:
      "Experience a powerful night of worship and prayer at our Monthly Prayer Vigil. Set aside time to connect deeply with God through worship, scripture and prayer. This evening is open to everyone come as you are to pray, reflect, and seek renewal together as a community.",
    timeFrame: "Meets last Fridays at 10pm",
  },
];

export const EventsData = [
  {
    images: "",
    title: "Sunday Worship Service",
    bodyText:
      "Leads prayers for the church, community, and world, including intercessory prayer sessions, vigils, and fasting.",
    timeFrame: "Meets Sundays at 10:30 A.M.",
  },
  {
    images: "",
    title: "Combined Bible Study & Prayer Services",
    bodyText:
      "Information about bible study. Worship team bringing God's presence, healing, and deliverance through powerful worship.",
    timeFrame: "Meets Fridays at 6 P.M.",
  },
  {
    images: "",
    title: "Monthly Vigil",
    bodyText: "Teaches God's Word, simplifying messages for understanding and approval before God.",
    timeFrame: "Meets last Fridays at 10 P.M.",
  },
];
