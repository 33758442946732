import React from "react";
import stock from "../../Assets/images/churrch.jpeg";

const GiveInfo = () => {
  const informationBlock = [
    {
      title: "offering",
      message:
        " Offerings go beyond the tithe. They’re an opportunity to give from the heart, whether to support specific ministries, care for those in need, or expand the church’s reach.",
      background: "#825DC3",
    },

    {
      title: "Building Project",
      message:
        " Our building project is more than just constructing a space—it’s about creating a homefor worship, connection, and outreach. Your giving helps build a place where lives will be transformed for years to come.",
      background: "#C96F20",
      video: stock,
    },

    {
      title: "Tithe",
      message:
        "Tithing—giving 10% of what God has blessed us with—is a way to honor Him and supportthe ongoing mission of the church. It’s a step of faith that invites God’s provision and blessings into our lives (Malachi 3:10).",
      background: "#2A826C",
    },
  ];
  return (
    <div className="grid  grid-cols-2 gap-2  relative mobile:grid-cols-1 mobile:h-full">
      {informationBlock?.map((item, index) => (
        <div
          key={index}
          className={`bg-[${item.background}] h-[100%] [&:nth-child(2)]:row-span-2  flex gap-5 justify-between p-5 py-5 rounded-xl group relative`}
          style={{ backgroundColor: item.background }}
        >
          <div className=" h-full flex flex-col justify-end w-[80%] py-0">
            {item.video && (
              <div className="relative my-5">
                <img src={stock} alt="hithere" loading="lazy" />
              </div>
            )}

            <p className="text-white font-semibold text-small-xl mobile:text-2xl">{item.title}</p>
            <p className="text-white leading-tight">{item.message}</p>
          </div>

          <div className="flex justify-end"></div>
        </div>
      ))}
    </div>
  );
};

export default GiveInfo;
