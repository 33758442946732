import React from "react";
import pastor from "../../Assets/Team/Pastor 1.png";
const Team = () => {

  return (
    <div className="p-[100px] mobile:px-[18px] mobile:py-10 smtab:p-10 Tablet:px-14">
      <p className="text-center text-footer  text-small-2xl font-Cormorant font-bold my-10">
        Meet The Pastor
      </p>
      <div className="flex flex-col items-center gap-10 mobile:flex-col smtab">
        <div className="flex w-full gap-10 mobile:flex-col">
          <div className="flex flex-col items-center justify-center w-full ">
            <div className="h-[400px] w-[400px] mobile:border mobile:w-full mobile:h-full smtab:w-full smtab:h-full">
              <img
                src={pastor}
                alt="displayphoto"
                className="rounded-md h-full w-full mobile:h-full mobile:w-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="my-5 text-center font-medium">
              <p className="text-footer ">Richard Ladokun</p>
              <p className="text-brown">Lead Pastor</p>
            </div>
          </div>

          <div className="flex flex-col gap-5 smtab:w-full">
            <p>
              Our Saviours Parish is part of Redeemed Christian Church Of God worldwide. The
              Redeemed Christian Church Of God worldwide is a global church organisation founded in
              1952, with a mission to spread the message of salvation across the world. Today RCCG,
              is one of the fastest- growing churches globally with thousands of parishes spanning
              numerous countries.
            </p>
            <p>
              The church is led by our General Overseer, Pastor Enoch Adejare Adeboye,
              affectionately known as Daddy G.O, who is recognised for his impactful leadership and
              dedication to the faith
            </p>
            <p>
              RCCG Our Saviours Parish journey began on December 4th, 2011 driven by a clear and
              compelling calling from God into ministry. It all started humbly in Pastor Ladokun's
              living room with his family alone, where they gathered to answer this divine call.
              With unwavering faith and commitment, what began as a small act of obedience has grown
              into the vibrant and thriving community we are today. From those early days, marked by
              heartfelt prayer and dedication, we have witnessed God’s guidance and blessing.
            </p>

            <p>
              Today we are blessed to be located at our current church address at 110-112 Norton
              Road Stockton-on-tees TS20 2AQ where we continue to share God’s message and extend his
              love to everyone who walks through the door.
            </p>
          </div>
        </div>
        {/* <div className="flex gap-14 flex-wrap w-full justify-center mobile:gap-1">
          {Team.slice(0, 6).map((item, index) => (
            <div key={index} className="flex flex-col items-center justify-center mobile:w-[48%]">
              <img
                src={item.displayPhoto}
                alt="displayphoto"
                className="rounded-full h-[150px] w-[150px] mobile:w-[80px] mobile:h-[80px] smtab:h-[80%] smtab:w-[80%]"
              />
              <div className="my-5 font-medium text-center ">
                <p className="text-footer ">{item.name}</p>
                <p className="text-brown">{item.role}</p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Team;
