import React, { useEffect, useState } from "react";
import axios from "axios";
import { CiStreamOff } from "react-icons/ci";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";

const Live = () => {
  const [video, setVideo] = useState();
  const [videoList, setVideoList] = useState();
  const apiKey = "AIzaSyDdKMSk-CK98EjGwRpUoV_5gCIalIABdoc";
  const CHANNEL_ID = "UCo38Z23-8Rn2YRd6r2AGW-Q";
  const url = `https://www.googleapis.com/youtube/v3/search`;

  const fetchLive = async () => {
    try {
      let res = await axios.get(url, {
        params: {
          part: "snippet",
          channelId: CHANNEL_ID,
          eventType: "live",
          type: "video",
          key: apiKey,
        },
      });
      setVideo(res.data.items[0].id.videoId);
    } catch (error) {
      console.log(error.response);
    }
  };

  const fetchRecentVideos = async () => {
    try {
      const res2 = await axios.get(url, {
        params: {
          part: "snippet",
          channelId: CHANNEL_ID,
          order: "date",
          type: "video",
          maxResults: 2,
          key: apiKey,
        },
      });
      setVideoList(res2.data.items);
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    fetchLive();
    fetchRecentVideos();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-white p-[100px] mobile:px-[18px] mobile:p-10  smtab:p-10 h-full Tablet:p-14">
      <div className="flex justify-between items-center mb-10 ">
        <div>
          <h1 className="text-small-2xl font-Cormorant font-semibold">Latest Sermon</h1>
        </div>

        <div>
          <Link
            to="https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE"
            className="px-3 py-2 rounded-full bg-buttons  text-white"
          >
            See all
          </Link>
        </div>
      </div>
      <div className="flex gap-5 h-[600px] w-[100%] mobile:flex-col smtab:flex-col">
        <div className="w-full h-auto smtab:h-[400px] mobile:h-[500px] relative">
          {!video ? (
            <div className="h-full">
              <div className="absolute right-1 top-2 bg-gray-500 p-2 group flex items-center gap-2 rounded-full overflow-hidden w-fit transition-transform duration-1000 mx-1 mobile:text-[14px]">
                <div>
                  <IconContext.Provider value={{ size: "30px" }}>
                    <CiStreamOff className="text-white" />
                  </IconContext.Provider>
                </div>
                <p className="absolute text-white left-[100%] group-hover:left-0 group-hover:duration-1000  group-hover:relative w-auto opacity-0 group-hover:opacity-100">
                  Service Live stream has ended. See you next Sunday
                </p>
              </div>

              <div className="h-full">
                <iframe
                  src={`https://www.youtube.com/embed/${videoList && videoList[0]?.id.videoId}`}
                  title="YouTube live stream"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  controls="0"
                  className="rounded-[15px] h-full w-full "
                ></iframe>
              </div>
            </div>
          ) : (
            <iframe
              src={`https://www.youtube.com/embed/${video}`}
              title="YouTube live stream"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              controls="0"
              className="rounded-[5%] h-full w-full smtab:rounded-[15px] mobile:rounded-[15px]"
            ></iframe>
          )}
        </div>

        <div className="flex flex-col gap-10 w-full mobile:flex-col">
          {videoList ? (
            <div className="h-full flex flex-col gap-5 mobile:flex-row smtab:flex-row">
              {videoList?.map((item, index) => (
                <div
                  key={index}
                  className="h-[50%] w-[100%] smtab:h-[300px]  mobile:h-full mobile:w-full"
                >
                  <iframe
                    src={`https://www.youtube.com/embed/${item.id.videoId}`}
                    title="YouTube live stream"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    controls="0"
                    className="rounded-[15px] h-full w-full "
                  ></iframe>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-small-2xl mobile:text-xl">
              <span>
                Previous Sermons can't be displayed at this time... click this link to access our
                youtube channel for all our sunday services{" "}
              </span>
              <Link
                to="https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE"
                className="text-buttons flex gap-3 items-center cursor-pointer"
              >
                OSP RCCG
                <IoLogoYoutube />
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Live;
