import axios from "axios";

const BASE_URL = `https://script.google.com/macros/s/AKfycbwhpR7h-WEOde63lE0T9TjxvqLveAWYwIZO0VeZSvJilaaD7C0ivRCjUcwwJRoU06NreA/exec`;

export const refetch = (func) => {
  return func;
};

export const getInfo = async () => {
  let result;
  try {
    const resp = await axios.get(`${BASE_URL}?action=fetchData`, {
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    result = resp;
  } catch (error) {
    console.log("error");
    result = "error";
  }
  return result;
};

export const fetchSingleEvent = async (id) => {
  let event;
  try {
    const response = await axios.get(`${BASE_URL}?action=${id}`, {
      headers: {
        "content-type": "application/json",
      },
    });

    event = response.data;
  } catch (error) {
    console.log("error");
  }

  return event;
};

export const fetchMagazines = async () => {
  let response;
  try {
    let res = await axios.get(`${BASE_URL}?action=Magazines`, {
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    response = res;
  } catch (error) {
    console.log("error");
  }
  return response;
};
