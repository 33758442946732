import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleEvent } from "../Resources/request";
import { HashLoader } from "react-spinners";

const SingleEvent = () => {
  const { eventId } = useParams();
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEvent = async () => {
    setIsLoading(true);
    const getData = await fetchSingleEvent(eventId);
    if (getData) {
      setIsLoading(false);
      setFetchedData(getData?.data);
    } else {
      console.log(getData);
    }
  };

  useEffect(() => {
    fetchEvent();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log(fetchedData);
  // });

  const setTime = (date) => {
    const setDate = new Date(date).getHours();
    if (setDate > 12) {
      return `${setDate - 12}pm`;
    } else if (setDate < 12) {
      return `${setDate}am`;
    }
  };
  const mapped = fetchedData.map((datas) => ({
    Title: datas?.Title,
    Description: datas?.Description,
    schedule: new Date(datas.schedule).toDateString(),
    time: setTime(datas.time),
    images: datas?.images,
    phone: datas.phone,
  }));

  return (
    <div className="p-[100px] mobile:py-14 mobile:px-10 smtab:p-10 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%] h-full">
      {isLoading ? (
        <div className="h-[500px] flex items-center justify-center">
          <HashLoader color="#07225C" />
        </div>
      ) : (
        <div className="h-full flex items-center">
          {mapped.length !== 0 &&
            mapped?.map((item, index) => (
              <div
                key={index}
                className="flex  items-center h-[500px] mobile:flex-col mobile:h-auto  gap-10"
              >
                <div className="h-full my-10">
                  <p className="text-small-2xl font-semibold leading-loose mobile:leading-tight ">
                    {item.Title}
                  </p>
                  <p>{item.Description}</p>
                  <p>
                    <span>{item.schedule}, </span>
                    <span>{item.time}</span>
                  </p>
                </div>

                <div className="w-full h-full">
                  <img src={item.images} className="object-contain h-full w-full " alt="imgs" />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SingleEvent;
