import React, { useEffect, useState } from "react";
import logo from "../../Assets/image 2.svg";
import { IoIosArrowDown } from "react-icons/io";
import { IconContext } from "react-icons";
const Beliefs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {});

  //   1) To make heaven.

  // 2) To take as many people with us.

  // 3) To have a member of RCCG in every family of all nations.

  // 4) To accomplish No. 1 above, holiness will be our lifestyle.

  // 5) To accomplish No. 2 and 3 above, we will plant churches within five minutes walking distance in every city and town of developing countries and within five minutes driving distance in every city and town of developed countries.

  // 6) We will pursue these objectives until every Nation in the world is reached for the Lord Jesus Christ
  const misvis = [
    {
      id: 1,
      title: "Vision & Mission",
      text: "<li>To make heaven.</li> <li>To take as many people with us.</li> <li>To have a member of RCCG in every family of all nations.</li> <li>To accomplish No. 1 above, holiness will be our lifestyle.</li> <li>To accomplish No. 2 and 3 above, we will plant churches within five minutes walking distance in every city and town of developing countries and within five minutes driving distance in every city and town of developed countries.</li> <li>We will pursue these objectives until every Nation in the world is reached for the Lord Jesus Christ</li>",
    },
  ];
  return (
    <div className="bg-white flex justify-between items-center px-[100px] h-[400px] mobile:flex-col mobile:p-8 mobile:h-auto mobile:justify-start smtab:p-10 Tablet:p-14 mobile:px-[18px] mobile:py-10">
      <div className="w-full">
        <img className="h-[100px] w-[100px] " src={logo} alt="beliefs" loading="lazy" />
        <p className="font-Cormorant text-brown text-medium-xl font-bold mobile:leading-[3rem] my-5 leading-10">
          RCCG Worldwide <br /> Mission and Vision
        </p>
      </div>

      <div className="w-[100%] mobile:w-full smtab:w-full">
        {misvis.map((item) => (
          <div
            className="odd:border-b border-0  odd:border-[#d5c7ba] p-5 mobile:px-0"
            key={item.id}
          >
            <div
              className="flex justify-between items-center"
              onClick={() => {
                setActiveIndex(item.id);
                if (activeIndex !== item.id) {
                  setActiveIndex(item.id);
                }
                if (activeIndex === item.id) {
                  setActiveIndex(0);
                }
              }}
            >
              <p className="font-semibold text-brown text-xl mobile:text-[20px] mb-2">
                {item.title}
              </p>
              <IconContext.Provider value={{ size: "25px", color: "#633000" }}>
                <IoIosArrowDown />
              </IconContext.Provider>
            </div>
            {/* {activeIndex === item.id && ( */}
            <ul
              className="px-3 text-[14px] list-disc mx-3"
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></ul>
            {/* )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Beliefs;
