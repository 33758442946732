import React from "react";
import childchurch from "../../Assets/Children.jpg";
import teenage from "../../Assets/images/teens.jpg";
import youth from "../../Assets/images/youths.jpg";
const OurMinistries = () => {
  const MinistriesData = [
    {
      image: childchurch,
      title: "Children Church",
      text: "Nurturing young hearts with fun, faith-filled lessons, stories, songs, and activities.",
    },
    {
      image: teenage,
      title: "Teenager’s Church",
      text: "Engaging teens with faith, fellowship, and relevant teachings for personal growth.",
    },
    {
      image: youth,
      title: "Youth Church",
      text: "Empowering young adults through worship, discussion, and leadership opportunities.",
    },
  ];
  return (
    <div className=" h-auto p-[100px]  mobile:px-[18px] mobile:py-10  smtab:p-10 Tablet:p-14">
      <div className="text-center mobile:text-left">
        <p className="text-brown font-bold text-[14px] mb-5">OUR MINISTRIES</p>
        <h2 className="font-Cormorant text-small-xl font-semibold text-footer mobile:text-3xl leading-[2.5rem] my-5">
          Empowering Believers Across
          <br /> Church Ministries
        </h2>
      </div>

      <div className="flex gap-10 my-5 justify-between mobile:flex-col smtab:gap-5 smtab:flex-wrap Tablet:flex-wrap w-full">
        {MinistriesData?.map((ministry, index) => (
          <div
            className=" flex flex-col justify-center items-center  gap-5 smtab:w-[45%] Tablet:w-[30%] "
            key={index}
          >
            <div className=" h-[300px] w-[fill] rounded-2xl overflow-hidden ">
              <img
                src={ministry.image}
                alt="images"
                className=" h-full w-full  object-cover"
                loading="lazy"
              />
            </div>

            <div>
              <p className="text-semibold text-center text-3xl mobile:text-left ">
                {ministry.title}
              </p>
              <p className="text-center smtab:text-sm mobile:text-left mobile:text-[18px] text-gray-700">
                {ministry.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurMinistries;
