import React from "react";
import { ClipLoader } from "react-spinners";

const Button = ({ handleClick, btnStyle, title, btnLoad, disable }) => {
  return (
    <button
      onClick={handleClick}
      className={`"font-semibold rounded-full px-5 py-2 ${btnStyle} cursor-pointer flex items-center justify-center gap-3 cur`}
      disabled={disable}
    >
      {btnLoad && <ClipLoader size="15px" color="white" />}
      {title}
    </button>
  );
};

export default Button;
