import React, { useState } from "react";
import { Link } from "react-router-dom";
import GiveInfo from "../Components/Giving/GiveInfo";
import Button from "../Resources/Button";
import BankInfo from "../Components/Giving/BankInfo";

const Giving = () => {
  const [info, setInfo] = useState(false);
  return (
    <div className="">
      <div className="h-[50vh] bg-giveBanner bg-no-repeat bg-cover "></div>

      <div className="px-[100px] py-[50px] mobile:p-5 smtab:p-10 Tablet:p-14">
        <div>
          <p className="text-medium-3xl font-Cormorant font-bold text-footer">Giving</p>
          <div className="flex flex-col gap-5">
            <p className="w-[50%]  mobile:w-full">
              Why We Give
              <br />
              Giving is a response to God’s goodness and faithfulness. It’s an act of worship,
              gratitude, and trust, allowing us to partner with Him in making a difference in our
              church and community.
            </p>
          </div>
        </div>

        <div className="my-16">
          <p className="text-medium-xl font-bold text-black">Ways to give</p>
          <p className="w-[70%] mobile:w-full">
            Join Us We invite you to be part of what God is doing through your tithes, offerings,
            and contributions. Together, we can make an eternal impact. Thank you for your
            generosity!. If you still have questions please send us a message to: <br />
            <Link to="mailto:rccgoursaviours@yahoo.co.uk " className="cursor-pointer">
              rccgoursaviours@yahoo.co.uk{" "}
            </Link>
            or call{" "}
            <Link to="tel:+44 7935 274961" className="cursor-pointer">
              +44 7935 274961
            </Link>
            <span className="block my-5">
              You can also give by cash or cheque at any of our gatherings.
            </span>
          </p>
        </div>

        <div>
          <div className="w-fit relative flex border p-2 rounded-full">
            <Button
              btnStyle={`${!info && "bg-brown rounded-full text-white"} p-1 px-2`}
              title="Give Online"
              handleClick={() => {
                setInfo(false);
              }}
            />
            <Button
              btnStyle={`${info && "bg-brown rounded-full text-white"} stex-black `}
              title="Bank Transfer"
              handleClick={() => {
                setInfo(true);
              }}
            />

            {/* <div className="absolute top-0 bottom-0 left-0 right-0 bg-brown "></div> */}
          </div>
          <div className="my-10 h-[500px] mobile:h-full">{info ? <BankInfo /> : <GiveInfo />}</div>
        </div>
      </div>
    </div>
  );
};

export default Giving;
