import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import {
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import { Link } from "react-router-dom";
import Form1 from "../Components/Contactus/Form1";
import { IoMail } from "react-icons/io5";
import { PiYoutubeLogoFill } from "react-icons/pi";
import { FaSquareFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { RiTwitterXLine } from "react-icons/ri";

import axios from "axios";

export const socials = [
  {
    icon: <IoMail />,
    text: "Send an email",
    link: "mailto:rccgoursaviours02@gmail.com",
  },
  {
    icon: <PiYoutubeLogoFill />,
    text: "Watch Online",
    link: "https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE",
  },
  {
    icon: <FaSquareFacebook />,
    text: "Facebook",
    link: "https://www.facebook.com/oursavioursparishUK",
  },
  {
    icon: <AiFillInstagram />,
    text: "instagram",
    link: "https://www.instagram.com/rccgoursavioursparish/",
  },
  {
    icon: <RiTwitterXLine />,
    text: "twitter",
    link: "https://x.com/rccgoursaviours",
  },
];

const ContactUs = () => {
  const [toggleForm, setToggleform] = useState(1);
  const [value, setValue] = useState("inquiry");
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [completed, setCompleted] = useState(false);
  // const [checked, setChecked] = useState(false);
  const [Inquiry, setInquiry] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    Phone: "",
    Question: "",
    action: "question",
  });
  const [prayerRequest, setPrayerRequest] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    Phone: "",
    Prayer: "",
    action: "prayer",
  });

  console.log(Inquiry.Phone);

  useEffect(() => {
    if (value === "inquiry") {
      setToggleform(1);
    } else {
      setToggleform(2);
    }
  }, [value]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInquiry({ ...Inquiry, [name]: value });
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(Inquiry).every((value) => value.trim() !== "");
    setValid(allFieldsFilled);
  }, [Inquiry]);

  const handlePrayers = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setPrayerRequest({ ...prayerRequest, [name]: value });
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(prayerRequest).every((value) => value.trim() !== "");
    setValid(allFieldsFilled);
  }, [prayerRequest]);

  const handleSubmitQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await axios.post(
        `https://script.google.com/macros/s/AKfycbwhpR7h-WEOde63lE0T9TjxvqLveAWYwIZO0VeZSvJilaaD7C0ivRCjUcwwJRoU06NreA/exec`,
        new URLSearchParams(Inquiry).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data === "Success") {
        setLoading(false);
        setCompleted(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSubmitPrayers = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let response = await axios.post(
        `https://script.google.com/macros/s/AKfycbwhpR7h-WEOde63lE0T9TjxvqLveAWYwIZO0VeZSvJilaaD7C0ivRCjUcwwJRoU06NreA/exec`,
        new URLSearchParams(prayerRequest).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data === "Success") {
        setLoading(false);
        setCompleted(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCompleted(false);
    }, [4000]);
  }, [completed]);

  return (
    <div className="p-[100px] mobile:px-8 smtab:px-14 Tablet:px-14 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
      <div className="flex justify-between gap-5 flex-wrap w-full">
        <div className="w-[45%] mobile:w-full smtab:w-full ">
          <p className="text-medium-3xl font-Cormorant font-bold text-footer tracking-tight">
            Contact us
          </p>
          <div className="my-3 mobile:my-5">
            <p className="text-2xl leading-relaxed">
              Our church is blessed with a diverse range of ministries dedicated to equip and
              empower members to fulfill their God-given purpose, serve the community, and support
              the church's mission.
            </p>

            <ul className="my-10 text-2xl flex flex-col gap-5 mobile:px-1 mobile:w-full">
              <p className="text-brown text-small-xl font-Cormorant font-semibold">
                Times & Location
              </p>
              <li className="flex flex-col">
                <span className="font-semibold">Sundays - Worship Service</span>
                <span>Morning Service at 10:30am</span>
                <span>Afternoon Service at 3:00pm</span>
              </li>
              <li className=" flex flex-col">
                <span className="font-semibold">
                  Fridays — Combined Bible Study & Prayer Services
                </span>
                <span>6:00pm Prompt</span>
              </li>
              <li className="flex flex-col">
                <span className="font-semibold">Vigils</span>
                <span>Every last Friday of the month: 10:00pm Prompt</span>
              </li>
              <li>
                <span className=" ">
                  RCCG Our Saviour’s Parish, 112A Norton Road, Stockton-on-Tees, TS20 2AQ
                </span>
              </li>
            </ul>

            <div className="flex gap-5 items-center">
              <Link
                className="border-2 border-buttons text-buttons rounded-full px-5 py-2"
                to="https://maps.app.goo.gl/LKWB719BxLtN1X1u5"
              >
                Get Driving Directions
              </Link>{" "}
              <div>
                <Link
                  to="https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE"
                  className="px-5 py-3 rounded-full bg-buttons  text-white "
                >
                  Watch Online
                </Link>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* forms section  */}
        <div className="w-[50%] smtab:w-full mobile:w-full overflow-hidden rounded-2xl px-5 py-10 bg-white">
          {/* selections */}
          <div className=" flex items-center gap-5 w-full mobile:gap-2">
            {/* Enquiry selection */}
            <div
              className="flex items-center border border-brown w-full p-3 rounded-2xl cursor-pointer mobile:flex-col mobile:items-start mobile:p-2"
              onClick={() => setValue("inquiry")}
            >
              <div className="w-[100%]">
                <p className="block font-semibold ">General Inquiry</p>
                <p className="text-[#555555]">Ask us about anything</p>
              </div>
              <div>
                <IconContext.Provider value={{ size: "25px", color: "#633000" }}>
                  {toggleForm === 1 ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />}
                </IconContext.Provider>{" "}
              </div>
            </div>

            {/* Prayer Selector */}
            <div
              className="flex items-center border border-brown w-full p-3 rounded-2xl cursor-pointer mobile:flex-col mobile:items-start mobile:p-2"
              onClick={() => setValue("prayers")}
            >
              <div className="w-[100%]">
                <p className="font-semibold">Prayer Request</p>
                <p className="text-[#555555]">Need us to pray for you?</p>
              </div>
              <div>
                <IconContext.Provider value={{ size: "25px", color: "#633000" }}>
                  {toggleForm === 2 ? <IoIosCheckmarkCircle /> : <IoIosCheckmarkCircleOutline />}
                </IconContext.Provider>
              </div>
            </div>
          </div>

          <div className="my-5">
            {toggleForm === 1 ? (
              <div>
                <div>
                  <p className="font-bold">Inquiries</p>
                  <p>
                    Do you have questions? Fill out the inquiry form and it will be sent to a member
                    of the team to have your questions answered.
                  </p>
                </div>
                <div className="my-5">
                  <Form1
                    formState={Inquiry}
                    setFormState={setInquiry}
                    handleChange={handleChange}
                    submit={handleSubmitQuestion}
                    type="questions"
                    isLoading={loading}
                    isValid={valid}
                    confirmed={completed}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <p className="font-bold">Prayer Request</p>
                  <p>
                    Do you need prayer today? Fill out the prayer request form and it will be sent
                    to a member of the prayer team.
                  </p>
                </div>
                <div className="my-5">
                  <Form1
                    formState={prayerRequest}
                    setFormState={setPrayerRequest}
                    handleChange={handlePrayers}
                    submit={handleSubmitPrayers}
                    type="prayer"
                    isLoading={loading}
                    isValid={valid}
                    confirmed={completed}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-brown text-white rounded-xl p-8 my-10 mobile:px-2">
        <p className="font-Cormorant font-semibold text-medium-xl text-center mobile:text-xl">
          Connect with us{" "}
        </p>
        <div className="flex item-center gap-14 justify-center my-10 mobile:gap-5 flex-wrap">
          <IconContext.Provider value={{ size: "50px", color: "#633000" }}>
            {socials.map((item, index) => (
              <Link
                key={index}
                className="flex items-center flex-col gap-10 cursor-pointer"
                to={item.link}
              >
                <div className="rounded-full bg-[#FCF2E7] w-fit p-3">{item.icon}</div>
                <p className="text-[14px]">{item.text}</p>
              </Link>
            ))}
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
