import React from "react";

const BankInfo = () => {
  const bankinfo = [
    {
      title: "Bank Name",
      text: "Barclays",
    },

    {
      title: "Account Name",
      text: "The Redeemed Christian Church of God (Our Saviour's Parish)",
    },
    {
      title: "Sort Code",
      text: "20-82-18",
    },
    {
      title: "Account Number",
      text: "13519910",
    },
    {
      title: "References",
      text: "References:Your first name by the purpose  e.g. “building”, “tithe” , “offering”",
    },
  ];
  return (
    <div>
      {bankinfo?.map((item, index) => (
        <div key={index} className="my-5">
          <p className="text-gray-500 font-semibold leading-loose">{item.title} :</p>
          <p className="font-semibold">{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default BankInfo;
