import React from "react";
import logo from "../Assets/image 2.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="bg-footer h-[full] py-[5%] px-24 mobile:px-[18px] mobile:py-10 mobile:h-full smtab:p-8 smtab:text-[12px]">
      <div className="flex justify-between gap-10 mobile:flex-col">
        {/* OSP LOGO AND CHURCH NAME */}

        <div className="w-[70%] Tablet:w-auto">
          <div>
            <img src={logo} alt="alt" className="h-[100px] mobile:h-[60px]" loading="lazy" />
          </div>

          <div className="text-white">
            <p>
              The Redeemed Christian
              <br />
              Church of God
            </p>
            <p className="font-bold leading-loose">Our Saviour’s Parish</p>
          </div>
        </div>

        {/* SERVICES */}
        <div className="flex mobile:flex-wrap smtab:flex-wrap w-full gap-14 ">
          <div className="w-full smtab:w-[60%]">
            <h1 className="text-3xl font-Cormorant font-semibold text-white">Our Services</h1>

            <div className="text-white my-4 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <p> Sundays</p>
                <ul className="">
                  <li>First Service : 10:30am Prompt</li>
                  <li>Second Service : 3:00pm Prompt</li>
                  <li className=" my-3">
                    <span className="">Vigil : </span>
                    <span>Every last Friday of the month: 10:00pm Prompt</span>
                  </li>
                </ul>
              </div>
              <p>Fridays: 6:00pm Prompt</p>
            </div>

            <div>
              <Link
                to="https://youtube.com/@rccgoursavioursparish?si=ILh5iN5HS2-jfRvE"
                className="px-3 py-2 rounded-full bg-buttons  text-white"
              >
                Watch Online
              </Link>
            </div>
          </div>
          {/* church Address */}
          <div className="w-full">
            <h1 className="text-3xl font-Cormorant font-semibold text-white ">
              Church & Office Address
            </h1>

            <div className="text-white my-4">
              <p className="my-1">
                RCCG Our Saviour’s Parish, 112A Norton Road, Stockton-on-Tees, TS20 2AQ
              </p>

              <div className="my-5">
                <p>Phone: +44 (07935274961)</p>
                <p className="*:">Phone: +44 (07957730151)</p>
              </div>

              <p>Email: rccgoursaviours@yahoo.co.uk</p>
            </div>

            <Link
              className="border-2 border-white text-white rounded-full px-5 py-2"
              to="https://maps.app.goo.gl/LKWB719BxLtN1X1u5"
            >
              Get Driving Directions
            </Link>
          </div>
          {/* get connected */}
          <div className="w-[50%]">
            <h1 className="text-3xl font-Cormorant font-semibold text-white">Get Connected</h1>

            <ul className="text-white ">
              <li className="cursor-pointer my-2">
                <HashLink smooth to="/services#new">
                  I'm New
                </HashLink>{" "}
              </li>
              <li className="cursor-pointer my-2">
                <Link to="/services">Worship Services</Link>{" "}
              </li>
              <li className="cursor-pointer my-2">
                <Link to="/about">About</Link>{" "}
              </li>
              <li className="cursor-pointer my-2">
                <Link to="/give">Give</Link>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
