import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";
import { AiOutlineCloudDownload } from "react-icons/ai";

const Tab = ({ dataSet }) => {
  const locate = useLocation();
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);

  useEffect(() => {
    if (locate.pathname === "/serve") {
      setState1(true);
    } else if (locate.pathname === "/events") {
      setState2(true);
    } else if (locate.pathname === "/magazine") {
      setState3(true);
    }
  }, [locate.pathname]);

  return (
    <div className="flex flex-wrap gap-10 ">
      {dataSet?.map((item, index) => (
        <div
          key={index}
          className="w-[30%] flex flex-col gap-3 mobile:w-[100%] smtab:w-[45%] h-fit group"
        >
          <div className="h-[450px] w-[100%]  rounded-xl overflow-hidden">
            <img
              src={item.images}
              className="object-cover h-full w-full group-hover:scale-150 duration-1000"
              alt="img"
              loading="lazy"
            />
          </div>
          <div className="flex flex-col gap-1 h-full">
            <p className="font-semibold mt-2">{item.Title}</p>
            <p>{item.Description}</p>
            <p>
              {item.schedule} {item.time}
            </p>
            {state1 ? (
              // <Link
              //   to={`tel:${item.phone}`}
              //   className="flex items-center gap-2 my-2 cursor-pointer"
              // >
              //   <p>Contact Adedayo</p>
              //   <IoArrowForward />
              // </Link>
              ""
            ) : state2 ? (
              <Link to={`/events/${index}`} className="flex items-center gap-2 my-2 cursor-pointer">
                <p>View Events</p>
                <IoArrowForward />
              </Link>
            ) : (
              state3 && (
                <Link to={`${item.link}`} className="flex items-center gap-2 my-2 cursor-pointer">
                  <p>Download</p>
                  <AiOutlineCloudDownload />
                </Link>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tab;
