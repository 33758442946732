import React, { useEffect, useState } from "react";
import Tab from "../Components/serviceComponents/Tab";
import { getInfo } from "../Resources/request";
import { HashLoader } from "react-spinners";
import { TbWifiOff } from "react-icons/tb";
import { IconContext } from "react-icons";

const Events = () => {
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchEvents = async () => {
    setIsLoading(true);
    let events = await getInfo();
    if (events.data) {
      setEventsData(events?.data?.data);
      setIsLoading(false);
    } else if (events === "error") {
      console.log(events);
      setIsLoading(false);
      setErrorMessage("Network Error");
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const setTime = (date) => {
    const setDate = new Date(date).getHours();
    if (setDate > 12) {
      return `${setDate - 12}pm`;
    } else if (setDate < 12) {
      return `${setDate}am`;
    }
  };
  const ospData = eventsData.map((datas) => ({
    Title: datas?.Title,
    Description: datas?.Description,
    schedule: new Date(datas.schedule).toDateString(),
    time: setTime(datas.time),
    images: datas?.images,
    phone: datas.phone,
  }));

  return (
    <div className="p-[100px] mobile:px-8 smtab:px-10 Tablet:px-14 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
      <div className="text-center my-5">
        <p className="text-medium-2xl font-Cormorant font-semibold text-footer leading-tight">
          Our Events
        </p>
        <p>Check out what’s happening at OSP!</p>
      </div>

      <div className="w-full">
        {isLoading ? (
          <div className="flex items-center justify-center my-10 h-[50vh]">
            <HashLoader color="#07225C" />
          </div>
        ) : (
          <Tab dataSet={ospData} />
        )}
      </div>
      <div>
        {errorMessage !== "" && (
          <div className="text-center my-14 flex flex-col items-center">
            <div>
              <IconContext.Provider value={{ size: "40px" }}>
                <TbWifiOff />
              </IconContext.Provider>
            </div>
            <p className="text-small-xl font-bold ">{errorMessage}</p>
            <p>check your internet connectivity or relaod the page</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Events;
