import React from "react";
import Button from "../../Resources/Button";
import { useNavigate } from "react-router-dom";
import Video from "../../Resources/Video";
import video from "../../Assets/Videos/osp.mp4";

const Brief = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center p-[100px] gap-28 mobile:px-[18px] mobile:py-10 mobile:flex-col mobile:gap-10 smtab:p-10 smtab:flex-col smtab:gap-5 Tablet:p-14">
      <div className="w-full leading-tight mobile:w-full smtab:w-full Tablet:w-full">
        <p className="text-brown font-bold text-[14px] mb-5">ABOUT US</p>
        <p className="text-medium-3xl font-Cormorant font-extrabold text-footer tracking-tight mobile:text-medium-xl mobile:leading-[2.5rem] my-5">
          Welcome to Our
          <br />
          Saviour's Parish
        </p>

        <div className="text-footer leading-7  flex flex-col gap-3">
          <p>
            When you visit us, you will be welcomed into a friendly and positive environment by
            people who are genuinely happy to see you.
          </p>
          <p>We strive to create an atmosphere that feels like home, where everyone is valued.</p>
          <p>
            Our services feature uplifting music and inspiring messages from our pastors, designed
            to encourage and uplift. We are a family-friendly church with dedicated children’s
            section that cater to various age groups, ensuring and nurturing space for kids.
          </p>
          <p>
            Ample parking is available for your convenience, and we have a special welcome pack
            ready just for you.
          </p>
          <p>
            Come and experience the warmth of our community, join us as we work together to inspire
            positive change and build a stronger, more connected community.
          </p>
        </div>

        <Button
          title="Learn More"
          btnStyle="bg-buttons text-white my-10"
          handleClick={() => navigate("/about")}
        />
      </div>

      <div className=" relative h-[700px] w-[50%] desktop:w-[50%] overflow-hidden rounded-xl mobile:w-full">
        {/* <img src={collage} alt="altVideo" className="object-cover h-full w-full" /> */}
        <Video video={video} vidStyle="w-full h-full mobile:w-full smtab:w-full" />
      </div>
    </div>
  );
};

export default Brief;
