import React from "react";
import collage from "../Assets/Videos/collage.gif";

import { Link } from "react-router-dom";
import { IoArrowForward } from "react-icons/io5";

const Gallery = () => {
  return (
    <div className="h-full p-[150px] flex gap-10 items-center mobile:flex-wrap mobile:px-10 mobile:py-24 mobile:gap-2 smtab:px-14 smtab:py-24 smtab:items-end smtab:gap-5">
      <div className=" relative h-[700px] w-[100%] rounded-xl overflow-hidden">
        <img src={collage} alt="altVideo" className="object-cover h-full w-full" />
        {/* <Video video={video} vidStyle="w-[400px] h-[500px] mobile:w-full smtab:w-full" /> */}
      </div>
      <div className="h-full flex flex-col justify-start  smtab:h-[700px] w-full">
        <div className=" p-5 rounded-xl bg-buttons text-white h-full group relative">
          <Link
            target="_blank"
            to="https://photos.app.goo.gl/WLBykFkEiM27pzYm9"
            className="text-small-2xl cursor-pointer flex flex-col justify-between h-full mobile:text-xl mobile:flex-row mobile:items-center"
          >
            <span>Click here to view our Gallery</span>
            <IoArrowForward className="relative group-hover:right-0 duration-1000" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
