import React from "react";
import { serviceList } from "../../Resources/Dataset";

const ServiceList = () => {
  return (
    <div className="mt-28 ">
      <div className="flex gap-14 flex-wrap smtab:gap-5 Tablet:gap-10 smtab:flex-col">
        {serviceList.map((item, index) => (
          <div
            key={index}
            className=" rounded-xl overflow-hidden w-[30%] mobile:w-full smtab:w-full Tablet:w-[30%]"
          >
            <div className="h-[300px] w-full overflow-hidden rounded-xl ">
              <img
                src={item.images}
                alt="service"
                className="h-full w-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="w-[95%]">
              <p className="font-semibold text-xl my-3">{item.title}</p>
              <p className="text-">{item.bodyText}</p>
              <p className="leading-snug my-3">{item.timeFrame}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceList;
