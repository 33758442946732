import React from "react";
import tab from "../../Assets/aboutus.gif";

const AboutBrief = () => {
  return (
    <div className="flex p-[100px] pb-[30px] flex-col smtab:px-10 Tablet:px-14 gap-10 mobile:px-[18px]">
      <div className="flex justify-between gap-5 flex-wrap w-full">
        <div className="w-[40%] mobile:w-full smtab:w-full">
          <p className="text-medium-3xl font-Cormorant font-bold text-footer tracking-tight">
            About Us
          </p>
          <div className="my-10 mobile:my-5 smtab:my-5">
            <p className="text-2xl leading-relaxed text-footer">
              Our Saviour's Parish is a vibrant community of faith within the Redeemed Christian
              Church of God (RCCG) worldwide, located in Norton on Tees, UK. We are passionately
              dedicated to:
            </p>

            <ul className="my-10 text-2xl px-3 flex flex-col gap-5 mobile:px-1 text-footer">
              <li>
                <span className="font-semibold">Nurturing the Local Community: </span>
                <span>Committed to fostering spiritual growth and well-being.</span>
              </li>
              <li>
                <span className="font-semibold">Imparting Christian Principles: </span>
                <span>Teaching the values and teachings of Jesus Christ.</span>
              </li>
              <li>
                <span className="font-semibold">Promoting Societal Well-Being: </span>
                <span>Striving to improve our community and beyond.</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="w-[50%] smtab:w-full mobile:w-full rounded-2xl overflow-hidden">
          <img src={tab} className="object-cover h-full w-full" alt="display" loading="lazy" />
        </div>
      </div>

      <div className="bg-brown text-center text-white p-10 rounded-2xl flex flex-col items-center mobile:text-left mobile:px-5">
        <p className="uppercase font-bold tracking-wide mobile:text-sm mobile:text-left w-full">
          Parish mandate
        </p>
        <p className="font-bold text-medium-xl font-Cormorant mobile:text-small-xl smtab:text-small-xl">
          Our Saviour's Parish (RCCG)
        </p>
        <p className="text-3xl w-[90%] my-5 mobile:text-1xl mobile:w-full smtab:text-xl ">
          The principal mission of Our Saviours Parish is to save all Redeemites from eternal
          sentences,
          <br /> Matthew 25:31-46
          <em></em>
        </p>
      </div>
    </div>
  );
};

export default AboutBrief;
