import React from "react";
import Button from "../../Resources/Button";
import Churchlogo from "../../Assets/icons/churchlogo";
import { useNavigate } from "react-router-dom";


const NewChurch = () => {
  const navigate = useNavigate();
  return (
    <div className="h-auto bg-footer flex justify-end p-[100px]  mobile:p-[18px] smtab:p-10 smtab:justify-between">
      <div className="flex w-[80%] gap-28 items-center mobile:flex-col mobile:w-full smtab:gap-10 smtab:w-full Tablet:w-full mobile:my-10 ">
        <div className="mobile:hidden">
          <Churchlogo height="200" width="2000" />
        </div>

        <div className="w-[30%] h-full mobile:w-full smtab:w-full">
          <p className="font-semibold font-Cormorant text-small-2xl text-white">New to church</p>
          <p className="my-5 text-white text-2xl">
            Come with us and we will do you good because God has spoken good about us —Numbers 10:29
          </p>
          <Button
            title="Learn more"
            btnStyle="bg-buttons text-white"
            handleClick={() => navigate("/about")}
          />
        </div>

        <div className="w-[30%] h-full mobile:w-full smtab:w-full">
          <p className="font-semibold font-Cormorant text-small-2xl text-white">
            Join a Department
          </p>
          <p className="my-5 text-2xl text-white">
            Join our church workforce—let's serve together! — 1 Peter 4:10
          </p>
          <Button
            title="Learn more"
            btnStyle="bg-buttons text-white"
            handleClick={() => navigate("/serve")}
          />
        </div>
      </div>
    </div>
  );
};

export default NewChurch;
