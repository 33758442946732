import React, { useState } from "react";
import Tab from "../Components/serviceComponents/Tab";
import Button from "../Resources/Button";
import excel from "../Assets/images/excel.jpeg";
import champions from "../Assets/images/champions.jpeg";
import welfare from "../Assets/images/welfare.jpeg";
import sundaySchool from "../Assets/images/sundaySchool.jpeg";
import sports from "../Assets/images/sports.jpeg";
import Evangelism from "../Assets/images/evangelism.jpeg";
import drama from "../Assets/images/Drama.jpeg";
import park from "../Assets/images/parks.jpeg";
import techies from "../Assets/images/techies.jpeg";
import ushers from "../Assets/images/ushering.jpeg";
import socials from "../Assets/images/socialmedia.jpeg";
import singles from "../Assets/images/singles.jpeg";
import sanitation from "../Assets/images/sanitation.jpg";
import mothers from "../Assets/mothers.jpeg";
import fathers from "../Assets/images/men.jpeg";
import bcol from "../Assets/images/BC.jpeg";
import sod from "../Assets/images/SOD.jpg";
import bus from "../Assets/images/RCCGBUS.jpeg";
import follow from "../Assets/images/followup.jpeg";
import youths from "../Assets/images/youths.jpeg";
import decor from "../Assets/images/decor.jpeg";
import fellowship from "../Assets/images/fellowship.jpg";
const Serve = () => {
  const worship = [
    {
      images: champions,
      Title: "Prayer Champions",
      Description:
        "Join our prayer team! Together, we can intercede and support the needs of our church, family, community, and the world through the power of prayer.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: excel,
      Title: "The Excel Voices",
      Description:
        "Our church choir plays a vibrant and essential role in our worship experience, committed to guiding the congregation in praise and worship through the gift of music. With a blend of voices and instruments, we aim to create an environment that welcomes God’s presence and unites our hearts in worship.",
      contactinfo: "Deaconess Kemi ",
      phone: "",
    },
    {
      images: sundaySchool,
      Title: "Sunday School",
      Description:
        "Our Sunday school is led by knowledgeable teachers who create a warm and engaging atmosphere. Together, we explore the scriptures and encourage a lifelong journey of faith",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: drama,
      Title: "Excel Production",
      Description:
        "Our Drama team is dedicated to expressing and communicating impactful messages of God's love, faith, and truth through captivating performances..",
      contactinfo: "Adebayo",
      phone: "",
    },
  ];

  const community = [
    {
      images: bus,
      Title: "Transportation",
      Description:
        " The team assist with transportation needs for services and events. Coordinates transportation logistics for various church events .",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: Evangelism,
      Title: "Evangelism",
      Description:
        "Our Evangelism team is committed to sharing the message of Christ’s love and salvation with our community and beyond.",
      contactinfo: "Deaconess Kemi ",
      phone: "",
    },
    {
      images: welfare,
      Title: "Welfare",
      Description:
        "Our Welfare team provides compassionate support to members and the community during tough times, offering a listening ear, help with daily needs, and connections to valuable resources.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: sports,
      Title: "Sports",
      Description:
        "Sports and Leisure Organises recreational activities and events to strengthen bonds within the congregation.",
      contactinfo: "Adebayo",
      phone: "",
    },

    {
      images: fellowship,
      Title: "House Fellowship",
      Description:
        "Join our RCCG House Fellowship for Bible study, prayer, and fellowship in a warm, family-friendly setting. Grow spiritually and build meaningful relationships. Find a fellowship near you and be part of a thriving faith community!",
      contactinfo: "Adebayo",
      phone: "",
    },
  ];
  const operations = [
    {
      images: park,
      Title: "OSP Park Attendants",
      Description: " Ensures organised parking and assists members and visitors.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: techies,
      Title: "Technical",
      Description: " Manages audio-visual equipments and other technical needs.",
      contactinfo: "Deaconess Kemi ",
      phone: "",
    },
    {
      images: socials,
      Title: "Social Media & Information",
      Description: "Share Church updates, events and messages across digital platforms",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: sanitation,
      Title: "Sanitation",
      Description: " Keeps Church premises clean and welcoming.",
      contactinfo: "Adebayo",
      phone: "",
    },
    {
      images: follow,
      Title: "Follow-up",
      Description: "Connects with new visitors and those in need for additional support.",
      contactinfo: "Adebayo",
      phone: "",
    },
    {
      images: ushers,
      Title: "Ushering & Protocol",
      Description: "Welcomes members, assists with seating, and maintains order during service.",
      contactinfo: "Adebayo",
      phone: "",
    },
    {
      images: decor,
      Title: "Decoration",
      Description: "Enhances the church spaces and handles all church publications.",
      contactinfo: "Adebayo",
      phone: "",
    },
  ];
  const groups = [
    {
      images: youths,
      Title: "Youths",
      Description:
        "Our youth groups are a place to connect, share, and inspire each other to grow in faith together. It’s a welcoming space where teens can explore their relationship with God and make lasting friendships along the way!",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: fathers,
      Title: "Excellent Men Fellowship",
      Description:
        " Our men fellowship group is a welcoming space for men of all ages to meet, support one another and connect in faith.",
      contactinfo: "Deaconess Kemi ",
      phone: "",
    },
    {
      images: mothers,
      Title: "Good Women Fellowship",
      Description:
        "Our women fellowship group provides a supportive community for women of all ages to grow in faith, pray together and encourage one another",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: singles,
      Title: "Singles Fellowship",
      Description:
        "Our Singles Fellowship is a warm and welcoming community for those looking to grow in faith and build meaningful relationships with others who share similar values. It’s a time of fellowship, where we come together to encourage one another and experience the love and unity of Christ.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: bcol,
      Title: "Bible College",
      Description:
        "This offers comprehensive faith-based education designed to equip individuals for a life of ministry and service.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
    {
      images: sod,
      Title: "School of Disciples (SOD)",
      Description:
        "This is a training program designed to provide believers with a profound comprehension of God's word, enhance their faith, and empower them for service.",
      contactinfo: "Pastor Mayowa",
      phone: "",
    },
  ];

  const [option, setOptions] = useState("work");
  return (
    <div>
      <div className="h-[50vh] bg-giveBanner bg-no-repeat bg-cover "></div>

      <div className="px-[100px] py-[50px] mobile:p-5 smtab:p-10 Tablet:p-14 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
        <div className="w-[60%] mobile:w-full">
          <p className="text-medium-3xl font-Cormorant font-bold text-footer">Church Departments</p>
          <p className="mb-10">
            Our church is blessed with a diverse range of ministries dedicated to equip and empower
            members to fulfill their God-given purpose, serve the community, and support the
            church's mission.
          </p>

          <p>
            Whether you're looking to deepen your faith, find community, or use your talents for
            God's glory, there's a place for you in one of our ministries. Explore the categories
            below to discover more about how you can get involved.
          </p>
        </div>

        <div className=" my-10 relative bg-white">
          <div className="border border-[#F4E7DB] rounded-full p-[2px] w-fit flex items-center   mobile:flex-wrap gap-1 mobile:rounded-lg bg-white z-50">
            <Button
              title="Spiritual Welfare"
              btnStyle={`${option === "work" ? "bg-brown border text-white " : ""}`}
              handleClick={() => setOptions("work")}
            />
            <Button
              title="Community"
              btnStyle={`${option === "community" ? "bg-brown border text-white" : ""}`}
              handleClick={() => setOptions("community")}
            />
            <Button
              title="Operations & Maintenance"
              btnStyle={`${option === "operations" ? "bg-brown border text-white" : ""}`}
              handleClick={() => setOptions("operations")}
            />
            <Button
              title="Natural Groups"
              btnStyle={`${option === "youth" ? "bg-brown border text-white" : ""}`}
              handleClick={() => setOptions("youth")}
            />
          </div>

          <div className="my-10">
            {option === "work" ? (
              <Tab dataSet={worship} />
            ) : option === "community" ? (
              <Tab dataSet={community} />
            ) : option === "operations" ? (
              <Tab dataSet={operations} />
            ) : (
              option === "youth" && <Tab dataSet={groups} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Serve;
