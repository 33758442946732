import React, { useState } from "react";
import ServiceSchedule from "../Components/Services/ServiceSchedule";
import ServiceList from "../Components/Services/ServiceList";
import { IoIosArrowDown } from "react-icons/io";
import { IconContext } from "react-icons";

const Services = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const dialog = [
    {
      id: 1,
      title: "What does a Sunday service look like?",
      body: "<p>We welcome you to join us each Sunday. We offer two uplifting services every Sunday</p> <p>Morning Service —10:30am Start your Sunday with inspiring worship and message grounded in the scripture. Come as you are— everyone is welcome!</p> <p>Afternoon Service— 3pm An opportunity for those that couldn’t make it to the morning service to join us in the afternoon for a time to worship, teach and pray and  also find encouragement for the week ahead</p>.",
    },
    {
      id: 2,
      title: "What about my Kids?",
      body: "<p>We have dedicated team that would look after your kids so that you can enjoy the service. Our trained volunteers create a safe and engaging space where children experience their own service filled with bible stories, songs, and activities.</p>",
    },
    {
      id: 3,
      title: "Is there free parking space?",
      body: "Yes, we have parking spaces available close to the Church for your convenience, we  also have park attendants that manages parking and assist members and visitors.",
    },
  ];
  return (
    <div>
      <div className="p-[100px] mobile:px-[18px] smtab:px-10 Tablet:px-14 large:max-w-[1440px] large:relative large:left-[50%] large:translate-x-[-50%]">
        <ServiceSchedule title="Our Services" />
        <ServiceList />
      </div>

      <div
        className="flex items-center gap-28 bg-[#F4E7DB] px-[100px] py-28 mobile:flex-col mobile:gap-5 mobile:px-8 Tablet:px-14 Tablet:gap-5 mobile:py-10 smtab:px-10"
        id="new"
      >
        <div className="w-full">
          <p className="text-brown text-small-2xl font-semibold my-3 Tablet:text-3xl">
            New to Our Saviour's Parish?
          </p>
          <p className="">
            Have you ever wondered about God? I mean really wondered about him? …That’s good because
            that means you have begun a spiritual journey and you have a sincere desire to find God
            and a hope for supernatural encounter.
          </p>
        </div>

        <div className="w-full">
          <div className="w-[100%] mobile:w-full smtab:w-full ">
            {dialog.map((item) => (
              <div
                className="border-b border-0 border-[#d5c7ba]  last:border-0 p-3 mobile:p-1"
                key={item.id}
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() => {
                    setActiveIndex(item.id);
                    if (activeIndex !== item.id) {
                      setActiveIndex(item.id);
                    }
                    if (activeIndex === item.id) {
                      setActiveIndex(0);
                    }
                  }}
                >
                  <p className="font-semibold text-brown my-2">{item.title}</p>
                  <IconContext.Provider value={{ size: "25px", color: "#633000" }}>
                    <IoIosArrowDown />
                  </IconContext.Provider>
                </div>
                {activeIndex === item.id && (
                  <div
                    className="py-1 text-sm flex flex-col gap-3"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
